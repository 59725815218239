import { MailEventStatus, SelectionType, TypeOfBbRoute, TypeOfShvRoute, TypeOfSignal } from './webapi';

// These are the labels that are shown at the frontend.
export const OrganizationLevelNL: Record<number, string> = {
  0: 'Organisatie',
  1: 'Suborganisatie',
  2: 'Team',
};

export const SelectionTypeNL: Record<SelectionType, string> = {
  0: 'Altijd uit',
  1: 'Standaard aan',
  2: 'Standaard uit',
  3: 'Altijd aan',
  4: 'Verborgen, aan',
  5: 'Verborgen, uit',
};

export const RoleNl: Record<string, string> = {
  Administrator: 'Desan beheerder',
  MesisAdministrator: 'Mesis beheerder',
  SuperUser: 'Superuser',
  Manager: 'Beheerder',
  MesisUser: 'Professional',
};

export const InstrumentLabels: Record<string, string> = {
  Mesis: 'Mesis',
  Signaal: 'Financiële signaallijst',
};

export const StatusLabels: Record<string, string> = {
  0: 'Gemaakt',
  1: 'Gestart',
  2: 'Afgerond',
};

export const ScenarioGroupsNl: Record<string, string> = {
  1: 'Beschermingsbewind',
  2: 'Schuldhulp',
  3: 'Signaal',
};

export const TypeOfBbRoutesNL: Record<number, string> = {
  [TypeOfBbRoute.IntakeRoute]: 'Intake',
  [TypeOfBbRoute.OutFlowRoute]: 'Uitstroombewind',
  [TypeOfBbRoute.Both]: 'Beide',
};
export const TypeOfShvRoutesNL: Record<number, string> = {
  [TypeOfShvRoute.WideSupport]: 'Brede ondersteuning',
  [TypeOfShvRoute.Behaviour]: 'Gedragsverandering',
  [TypeOfShvRoute.Both]: 'Beide',
};
export const TypeOfSignalNL: Record<number, string> = {
  [TypeOfSignal.Inventory]: 'Inventarisatie',
  [TypeOfSignal.Companies]: 'Bedrijven',
  [TypeOfSignal.Both]: 'Beide',
};

export const EmailStatusNL: Record<number, string> = {
  [MailEventStatus.Bounced]: 'Bounced',
  [MailEventStatus.Erroneous]: 'Fout',
  [MailEventStatus.NoEmailAddress]: 'Geen e-mailadres',
  [MailEventStatus.Scheduled]: 'Gepland',
  [MailEventStatus.Sending]: 'Aan het versturen',
  [MailEventStatus.Sent]: 'Verstuurd',
};
